<script>

    const DEVMODE = import.meta.env.DEV;

	import Header from './Header.svelte';
	import Footer from './Footer.svelte';


	//------------------------------------------------------------------------------------
	// from +layout.server.js
	//------------------------------------------------------------------------------------
	export let data;


	//====================================================================================
	// css
	//====================================================================================

    import "../app.css";
    import "./styles.css";


	//====================================================================================
	// view transition
	//====================================================================================

	import { preparePageTransition } from '$lib/page-transition';
	preparePageTransition();




	//====================================================================================
	// sentry
	//====================================================================================

	// import * as Sentry from "@sentry/svelte";

	// if (!DEVMODE) {

	// 	Sentry.init({
	// 		dsn: "https://9e3e15a3856549438443b91725e2dccc@o4504938178412544.ingest.us.sentry.io/4504939751735296",
	// 		integrations: [
	// 			Sentry.browserTracingIntegration(),
	// 			Sentry.replayIntegration({
	// 			maskAllText: false,
	// 			blockAllMedia: false,
	// 			}),
	// 		],
	// 		// Performance Monitoring
	// 		tracesSampleRate: 1.0, //  Capture 100% of the transactions

	// 		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// 		// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// 		tracePropagationTargets: [/^https:\/\/manamuran\.com\/req/],

	// 		// Session Replay
	// 		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	// 		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	// 	});

	// }



	//====================================================================================
	// transition
	//====================================================================================

	// function delayNavigation() {	return new Promise((res) => setTimeout(res, 100));}
	// onNavigate(async (navigation) => {Cannot find name 'onNavigate'.Parameter 'navigation' implicitly has an 'any' type.23047006Cannot find name 'onNavigate'.Parameter 'navigation' implicitly has an 'any' type.	// do some work immediately before the navigation completes
	// // optionally return a promise to delay navigation until it resolves	return delayNavigation();});


	import { onNavigate } from '$app/navigation';

	onNavigate((navigation) => {
		if (!document.startViewTransition) return;

		return new Promise((resolve) => {
			document.startViewTransition(async () => {
				resolve();
				await navigation.complete;
			});
		});
	});

	// import { page } from '$app/stores';
	// import { send, receive } from '$lib/components/fadeTransition.js';

    // $: console.log('+layout.svelte')
</script>



<div class="app" style="background:#ffffffcc">
	<Header />


	<!-- <main in:receive={{ key: $page.path }} out:send={{ key: $page.path }}>
		<slot />
	</main> -->

	<main>
		<slot />
	</main>

	<Footer
        bind:data={data}
    />
</div>
